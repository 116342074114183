/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  Header.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import Navbar from "./Navbar";
/**
 * Header component containing navigation and top bar icons.
 *
 * This component includes a navigation bar rendered by the Navbar component
 * and a fixed top bar with various icons for messaging, search, notifications,
 * and an additional icon. These icons provide quick access to different functionalities
 * or notifications within the application's header section.
 *
 * @returns {JSX.Element} - Rendered JSX header component.
 */
const Header = () => {
  return (
    <div>
      {/* Renders the Navbar component for navigation */}
      <Navbar />

      <div className="flex justify-end fixed top-0 border bg-gray-50 -z-0 border-b-1 border-b-[#000] p-1 w-full">
        {/**Meassage icon */}
        <img
          src="/message.png"
          alt="searchImage"
          className="w-[24px] h-auto m-2"
        />
        {/**Search icon */}
        <img
          src="/search.png"
          alt="searchImage"
          className="w-[24px] h-auto m-2"
        />
        {/**Notifications icon */}
        <img
          src="/notification.png"
          alt="searchImage"
          className="w-[24px] h-auto m-2"
        />
        <img
          src="/NoPath.png"
          alt="searchImage"
          className="w-[24px] h-auto m-2"
        />
      </div>
    </div>
  );
};

// Export the Header component as the default export.
export default Header;
