/********************************************************************************************
 *   COPYRIGHT (C) 2024 RIoT
 *   The reproduction, transmission or use of this document/file or its
 *   contents is not permitted without  written authorization.
 *   Offenders will be liable for damages. All rights reserved.
 *---------------------------------------------------------------------------
 *   Purpose:  NavBar.js
 *   Project:  RIoT
 *   IDE:	  VS Code
 ********************************************************************************************/
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faTachometerAlt,
  faUsers,
  faCalendarAlt,
  faChartBar,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
/**
 * Navbar component for displaying navigation menu and toggle button.
 *
 * This component renders a responsive sidebar navigation menu with icons and labels.
 * It includes a toggle button to expand or collapse the sidebar based on the isOpen prop.
 *
 * @param {boolean} isOpen - Controls the state of the sidebar (true for expanded, false for collapsed).
 * @param {function} toggleNavbar - Function to toggle the isOpen state of the sidebar.
 * @returns {JSX.Element} - Rendered JSX for the Navbar component.
 */
const Navbar = ({ isOpen, toggleNavbar }) => {
  return (
    <div
      className={`h-screen ${
        isOpen ? "w-48" : "w-16"
      } bg-[#08605F] text-white fixed transition-all duration-300`}
    >
      <div className="p-4 text-2xl font-bold text-center bg-[#08605F] font-serif relative">
        {isOpen && "RIoT"}
        {/* Button to toggle the sidebar visibility */}
        <button
          onClick={toggleNavbar}
          className="absolute top-1/2 right-[-12px] transform -translate-y-1/2 focus:outline-none bg-white rounded-full px-2"
        >
          {/* Icon to indicate sidebar toggle state */}
          <FontAwesomeIcon
            icon={isOpen ? faArrowLeft : faArrowRight}
            className="text-black text-[16px]"
          />
        </button>
      </div>
      <nav className="mt-10">
        <ul>
          {/* Dashboard navigation item */}
          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faTachometerAlt} className="w-6 mr-2" />
            {isOpen && <span>Dashboards</span>}
          </li>

          {/* Customers navigation item */}
          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faUsers} className="w-6 mr-2" />
            {isOpen && <span>Customers</span>}
          </li>

          {/* Schedules navigation item */}
          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faCalendarAlt} className="w-6 mr-2" />
            {isOpen && <span>Schedules</span>}
          </li>

          {/* Analytics navigation item */}
          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faChartBar} className="w-6 mr-2" />
            {isOpen && <span>Analytics</span>}
          </li>

          {/* Help navigation item */}
          <li className="p-4 hover:bg-[#177E89] flex items-center">
            <FontAwesomeIcon icon={faQuestionCircle} className="w-6 mr-2" />
            {isOpen && <span>Help</span>}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
