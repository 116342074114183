/* *
 * @file AppLayout.js
 * @description Main application file for setting up the React application.
 *
 * This file imports necessary modules and components, sets up the routing for the application,
 * and defines the main App component.
 *  - React and necessary hooks from 'react'
 * - Styles from 'index.css'
 * - Router and provider setup from 'react-router-dom'
 * - Layout component from './components/AppLayout'
 * - Lazy loading of BatteryState component from './components/BatteryState'
 * - A fallback component for loading states
 */
import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [footerPosition, setFooterPosition] = useState("fixed");
  const [contentPaddingBottom, setContentPaddingBottom] = useState(0);

  /**
   * useEffect Hook to Handle Window Scroll Event
   *
   * This useEffect hook adds an event listener for the window's scroll event
   * and updates the footer's position and content's bottom padding based on the
   * scroll position. Specifically:
   *
   * - If the user scrolls down (scroll position > 0):
   *   - The footer's position is set to "relative", allowing it to move with the content.
   *   - The content's bottom padding is set to "50px" to ensure proper spacing.
   * - If the user scrolls back to the top (scroll position = 0):
   *   - The footer's position is set to "fixed", keeping it at the bottom of the viewport.
   *   - The content's bottom padding is reset to "0".
   */
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 0) {
        setFooterPosition("relative");
        setContentPaddingBottom("50px");
      } else {
        setFooterPosition("fixed");
        setContentPaddingBottom(0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    /**
     * Layout Component Structure
     *
     * This layout component defines the overall structure and layout of the application.
     * It includes a header, a navigation bar, a main content area, and a footer.
     *
     * The component utilizes a flexible column layout to ensure the footer is always at the bottom
     * of the viewport or content, whichever is taller. It also incorporates conditional styling for
     * the sidebar and footer positions based on the state.
     */
    <div className="flex flex-col min-h-screen">
      {/* Render the Header component at the top */}
      <Header />

      <div className="flex flex-grow">
        <Navbar
          isOpen={isSidebarOpen} // State controlling the sidebar's visibility
          toggleNavbar={() => setIsSidebarOpen(!isSidebarOpen)} // Function to toggle the sidebar
        />

        <main
          className={`flex-grow pt-16 transition-all duration-300 ${
            isSidebarOpen ? "ml-48" : "ml-16"
          }`}
          style={{ paddingBottom: contentPaddingBottom }}
        >
          {/* Render dynamic content */}
          <Outlet />
        </main>
      </div>

      <Footer
        className={`${
          footerPosition === "fixed" ? "fixed bottom-0 left-0 right-0" : ""
        } bg-gray-200 p-4`} // Conditional fixed positioning for the footer
      />
    </div>
  );
};

//- The AppLayout component is exported as the default export from this module.
export default AppLayout;
